import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import api from '../services/api';
import { useAuth } from './auth';

interface ICreditContext {
  credits: ICredit;
  subCredit(): void;
  addCredit(): void;
  countCredits(): string;
  haveCredits(): boolean;
}

interface ICredit {
  extraCredits: number;
  finalPeriod: string;
  initialPeriod: string;
  wordingsRemaining: number;
  wordingsPerMonth: number;
  practicesPerMonth: number;
}

const CreditContext = createContext<ICreditContext>({} as ICreditContext);

const CreditProvider: React.FC = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const [credits, setCredits] = useState<ICredit>({} as ICredit);

  useEffect(() => {
    async function loadData() {

      if(!isAuthenticated) {
        return;
      }

      try {
        const response = await api.get('/profile/credits', {
          params: { exam_name: 'enem' }
        });

        console.log('LIST WORDING CREDITS QUANTITY >>>>>>>>>>>>>>>>>>>');
        console.log(response.data);

        setCredits(response.data);
      } catch (error) {
        console.log(error);
      }
    }

    loadData();
  }, [isAuthenticated]);

  const subCredit = useCallback(() => {
    if (credits.wordingsRemaining) {
      setCredits(oldCredits => ({
        ...oldCredits,
        wordingsRemaining: oldCredits.wordingsRemaining - 1,
      }));
    } else {
      setCredits(oldCredits => ({
        ...oldCredits,
        extraCredits: oldCredits.extraCredits - 1,
      }));
    }
  }, [credits]);

  const countCredits = useCallback(() => {
    if (Object.keys(credits).length === 0) {
      return '';
    }

    return String(credits.wordingsRemaining + credits.extraCredits);
  }, [credits]);

  const haveCredits = useCallback(() => !!(credits.wordingsRemaining + credits.extraCredits), [credits]);

  const addCredit = useCallback(() => {
    setCredits(oldCredits => ({
      ...oldCredits,
      wordingsRemaining: oldCredits.wordingsRemaining + 1,
    }));
  }, []);

  return (
    <CreditContext.Provider value={{ credits, subCredit, addCredit, haveCredits, countCredits }} >
      {children}
    </CreditContext.Provider>
  )
}

function useCredit(): ICreditContext {
  const context = useContext(CreditContext);

  return context;
}


export { CreditProvider, useCredit };



