import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useAuth } from '../../hooks/auth';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import Button from '../../components/Button';
import InputAnimate from '../../components/InputAnimate';
import ForgetPasswordModal from './ForgetPasswordModal';

import { trim } from 'lodash';
import axios from 'axios';
import UAParser from 'ua-parser-js';

import { Container, Logo, LogoMobile, Form, LandingInfo, LandingForm, ContentIntro, FormBox, ContentGrid1, ModalMaintenance, ModalChromeAndW7Detect } from './styles';

import logoPoxalulu from '../../assets/logo-poxalulu.svg';
import rocketImg from '../../assets/rocket.png';
import logo from '../../assets/chrome-logo.png';
import ReCAPTCHA from 'react-google-recaptcha';

const SigIn: React.FC = () => {
  const { login } =  useAuth();

  const inputEmail = useRef<HTMLInputElement>(null);
  const recaptchaRef = useRef<any>(null);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const [showRecaptcha, setShowRecaptcha] = useState(false);

  useEffect(() => {
    inputEmail.current?.focus();
  }, [])

  const handleMaintenance = useCallback((e) => {
    e.preventDefault();

    let modalMaintenance = withReactContent(Swal);

    modalMaintenance.fire({
      html: (
        <ModalMaintenance>
          <h1>🚨 Atenção, poxinha!</h1>
          <p>A Plataforma está em manutenção! Estamos atualizando alguns recursos para melhorar a qualidade do nosso serviço.</p>
          <p>
            Espero que entendam,
            <br />
            Atenciosamente, Poxalulu.
          </p>
        </ModalMaintenance>
      ),
      background: '#d14646',
      backdrop: 'rgba(110, 97, 198, .1)',
      showCancelButton: false,
      showConfirmButton: false,
      showCloseButton: true,
    });
  }, []);

  const handleDOMChange = useCallback(() => {
    const iframe = document.querySelector(
      'iframe[src*="recaptcha/api2/bframe"]',
    );

    const container = iframe?.parentNode?.parentNode?.firstChild as
      | HTMLElement
      | undefined;

    if (!container) {
      return;
    }

    container.onclick = () => {
      setLoading(false);

      toast.error('Erro no CAPTCHA! Cumpra a etapa de verificação.', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    };
  }, []);

  useEffect(() => {
    const domObserver = new MutationObserver(handleDOMChange);

    domObserver.observe(document.body, {
      childList: true,
    });
  }, [handleDOMChange]);

  const handleLogin = useCallback(async (e) => {
    e.preventDefault();

    recaptchaRef.current?.execute();

    // tira os espaços da string 3 vezes kkkk
    const emailTrined = trim(email.replace(/\s/g, '')).trim();

    try {
      setLoading(true);

      const token = await recaptchaRef.current?.executeAsync();
      if (showRecaptcha && !token) {
        toast.error('Erro no CAPTCHA! Cumpra a etapa de verificação.', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        setLoading(false);
        return;
      }

      await login({ email: emailTrined, password });
      window.scrollTo(0, 0);
    } catch (err) {
      console.log(err);

      recaptchaRef.current?.reset();

      if (axios.isAxiosError(err)) {
        const { browser, device, os } = UAParser();
        const { error } = err.response?.data || { error: 'default' };

        console.log({ browser, device, os });
        console.log('err::: ', error);

        //if(browser.name === 'Opera') { //test
        if(browser.name === 'Chrome' && os.name === 'Windows' && os.version === '7') {
          let MySwal = withReactContent(Swal);
          MySwal.fire({
            html: (
              <ModalChromeAndW7Detect>
                <img src={logo} alt="" />
                <h1>Seu navegador pode ser incompatível</h1>
                <p>
                  Detectamos que você utiliza uma <strong>versão antiga do Windows 7 (2009)</strong> e seu navegador
                  <strong> ({browser.name} - {browser.version})</strong> não atende aos protocolos de segurança
                  necessários para fazer login na Plataforma. <br /><br />

                  Nesses casos, indicamos que faça Download do navegador Mozila Firefox.
                </p>
                <a className="download" href="https://www.mozilla.org/pt-BR/firefox/new/" target="_blank" rel="noreferrer">Download do Mozila Firefox</a>
                <a href="https://educacione.com.br/contato/" target="_blank" rel="noreferrer">Se preferir, entre em contato com o Suporte</a>
              </ModalChromeAndW7Detect>
            ),
            background:'#fff',
            backdrop: '#1d23334e',
            showConfirmButton: false,
            showCloseButton: true,
            showCancelButton: false,
            allowOutsideClick: true,
            allowEscapeKey : true,
            padding: '32px 16px',
          });
        }

        console.log(err);
        toast.error(`🔒 E-mail/cpf ou senha inválidos!`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        setShowRecaptcha(true);
      }
    } finally {
      setLoading(false);
    }
  }, [email, password, showRecaptcha, login]);

  const handleForgetPassword = useCallback(() => {
    let forgetPasswordModal = withReactContent(Swal);

    forgetPasswordModal.fire({
      html: (
        <ForgetPasswordModal />
      ),
      background: '#fff',
      backdrop: 'rgba(110, 97, 198, .1)',
      showCancelButton: false,
      showConfirmButton: false,
      showCloseButton: true,
    });
  }, []);

    return(
        <Container>
          <ContentGrid1>
            <LandingInfo>
              <ContentIntro>
                <Logo>
                  <img src={logoPoxalulu} alt="Logo Poxalulu"/>
                </Logo>
                <h2>Seja bem vindo(a) a</h2>
                <h1>Plataforma Poxalulu</h1>
                <p>Estamos do seu lado e seu sucesso é o nosso! Queremos que você seja como tantos outros Poxinhas que todos os anos nos enviam depoimentos agradecendo pela ajuda e partilhando o mérito do bom desempenho com os nossos corretores!</p>
              </ContentIntro>
            </LandingInfo>

            <LandingForm>
              <FormBox className="frmm">
                <img className="rocket" src={rocketImg} alt="Voa, poxinha!"/>
                <LogoMobile>
                  <h2>Informe seus dados</h2>
                  <img src={logoPoxalulu} alt="Logo Poxalulu"/>
                </LogoMobile>
                <Form onSubmit={e => handleLogin(e)}>
                {/* <Form onSubmit={(e) => handleMaintenance(e)}> */}
                    <InputAnimate
                      label="E-mail"
                      type="text"
                      name="user"
                      onChange={(e) => setEmail(e.target.value)}
                      onlyWhite={true}
                      required
                      autoFocus
                      autoComplete="off"
                    />
                    <InputAnimate
                      label="Senha"
                      type="password"
                      name="password"
                      onChange={(e) => setPassword(e.target.value)}
                      onlyWhite={true}
                      required
                      autoComplete="off"
                    />
                    <div className="forget">
                      <p onClick={handleForgetPassword}>Não está conseguindo acessar? <span>Redefina a sua senha.</span> </p>
                    </div>
                    <div className="access-info">
                      <Button type="submit" color="success" loading={loading}>Entre em sua conta</Button>
                      {showRecaptcha && (
                        <div style={{ color: 'red', marginBottom: '16px' }}>
                          <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}
                            size="invisible"
                            onErrored={() => console.log(`error: void`)}
                            onExpired={() => console.log(`expired: void`)}
                          />
                        </div>
                      )}
                      <p className="lined-text">
                        <a href="https://www.pxtecnologia.com.br" target="_blank" rel="noreferrer">
                            2019 - {new Date().getFullYear()} Desenvolvido por: Px Tecnologia
                        </a>
                      </p>
                    </div>
                  </Form>
              </FormBox>
            </LandingForm>
          </ContentGrid1>
        </Container>
    );
}

export default SigIn;
